import React, { useState } from "react"
import moment from "moment"

import { Link } from "components/anti/link/link"
import { Card } from "components/anti/card/card"
import { Button } from "components/anti/buttons/buttons"
import imgDefault from "assets/img/bg_default.jpg"

export const UpdateList = ({ data, categories }) => {
  const [pagination, setPagination] = useState([0, 24])

  const changePage = isNext => {
    setPagination([pagination[0], pagination[1] + 24])
  }

  const amandUpdate = data
  const dataTabs = [
    { name: "All", target: "all" },
    ...categories.map(data => ({ name: data.name, target: data.slug })),
  ]

  return (
    <section className="pb-main pt-3 sc-aman-update-content">
      <div
        id="nav-tab"
        className="nav-tabs-wrapper nav-scrollable-wrapper scrollable-fade"
      >
        <div className="container">
          <div className="nav-scrollable">
            <ul className="nav nav-tabs" id="experiment" role="tablist">
              {dataTabs.map((nav, i) => (
                <li className={`nav-item`} key={i}>
                  <Link
                    to={
                      nav.target === "all"
                        ? `/aman-update`
                        : `/aman-update/${nav.target}`
                    }
                    className={`nav-link`}
                    activeClassName={`active`}
                  >
                    {nav.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-3">
          {amandUpdate.slice(pagination[0], pagination[1])?.map((data, i) => {
            const categories = data?.categories?.nodes[0]?.slug || "details"
            const j = pagination[1] > 15 ? i - pagination[1] : i
            return (
              <div className={`col-md-6 col-lg-4`} key={i}>
                <Card
                  variant="boxless"
                  img={data?.featuredImage?.node?.sourceUrl || imgDefault}
                  link={`/aman-update/${categories}/${data.slug}`}
                  title={data.title}
                  label={moment(data.date).format("MMM DD, YYYY")}
                  className={`fadeInUp d${2 + j} card-update hover-zoomIn`}
                  labelClassName={`fadeInUp d${3 + j}`}
                  titleClassName={`fadeInUp d${4 + j} h5`}
                />
              </div>
            )
          })}
        </div>
        {pagination[1] <= amandUpdate.length && (
          <div className="load-more mt-3">
            <Button
              variant="primary"
              className={`fadeInUp d${1}`}
              onClick={changePage}
            >
              Tampilkan Lebih Banyak
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}
