import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { UpdateList } from "components/pages/aman-update/list"
import { WFBCover } from "components/pages/wfb/cover"
// import { UpdateFeatured } from "components/pages/aman-update/featured"

const AmanUpdate = ({ pageContext }) => {
  const data = useStaticQuery(GET_AMANUPDATE_DETAILS)
  const blocks = data.wordPress.page.amanUpdatePage
  // const featured = pageContext?.tag?.posts?.nodes
  const allPosts = pageContext?.posts
    ? pageContext?.posts
    : pageContext?.data?.posts?.nodes
  const categories = pageContext?.categories

  return (
    <Layout>
      <SEO title="Aman Update" />
      <WFBCover data={blocks.cover} />
      {/* <UpdateFeatured data={featured} /> */}
      <UpdateList data={allPosts} categories={categories} />
    </Layout>
  )
}

export default AmanUpdate

const GET_AMANUPDATE_DETAILS = graphql`
  query AMAN_UPDATE {
    wordPress {
      page(id: "aman-update", idType: URI) {
        title
        amanUpdatePage {
          cover {
            title
            text
            image {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
